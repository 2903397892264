import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import LoadingBarGraphSvg from 'images/loading_icon/loading-bar-graph.svg';
import moment from 'moment';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import styles from './styles.js';

const propTypes = {
  /**
   * Width of the SVG. Its value will be applied to the height as well.
   */
  width: PropTypes.number,
  height: PropTypes.number,
  politician: PropTypes.object,
  size: PropTypes.string,
  isBarGraphOrTweetsSection: PropTypes.bool,
  dateOfTweetsFetch: PropTypes.string,
};

const defaultProps = {
  width: 8,
  height: 28,
  isBarGraphOrTweetsSection: false,
};

const LoadingIcon = (props) => {
  const {height, width} = props;
  const loadingIcon = (
    <img
      src={LoadingBarGraphSvg}
      alt={"An icon that indicates the page is loading."}
      css={styles.loadingIcon(width, height)}
    />
  );
  const dateOfTweetsFetch = moment.utc(props.dateOfTweetsFetch);
  const threeHoursAgo = moment.utc().subtract(3, 'hours');
  const isCategorizingTweets = dateOfTweetsFetch.isBefore(threeHoursAgo);

  if (props.isBarGraphOrTweetsSection && isCategorizingTweets) {
    return (
      <div css={styles.loadingIconWithExplainer}>
        {loadingIcon}
        <div css={styles.loadingExplainer(props.size)}>
          Sit tight. We're categorizing {props.politician.name}'s tweets...
        </div>
      </div>
    );
  }

  return loadingIcon;
};

LoadingIcon.propTypes = propTypes;
LoadingIcon.defaultProps = defaultProps;
export default LoadingIcon;
