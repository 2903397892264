export const addressSearchType = 'address';
export const zipcodeSearchType = 'zipcode';

export const searchTypeOptions = [
  {
    searchType: zipcodeSearchType,
    menuItem: 'by ZIP Code',
  },
  {
    searchType: addressSearchType,
    menuItem: 'by Address',
  },
  {
    searchType: 'politician',
    menuItem: 'by Politician Name',
  },
];
