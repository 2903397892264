import React from 'react';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';
import {searchPath} from '../../modules/constants/routePaths';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';

const LazySearchContainer = Loadable({
  loader: () => import(
    './containers/SearchContainer/SearchContainer'
  ),
  loading: LoadingIcon,
});

const SearchRoute = (
  <Route
    key="search"
    path={searchPath}
    component={LazySearchContainer}
    exact
  />
);

export default SearchRoute;
