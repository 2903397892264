import _ from 'lodash';

const rootPath = '/';
const politicianPath = '/politicians';
const searchPath = '/search';
const aboutPath = '/about';
const donatePath = '/donate';
const termsOfServicePath = '/terms-of-service';

export {
  rootPath,
  politicianPath,
  searchPath,
  aboutPath,
  donatePath,
  termsOfServicePath,
};
