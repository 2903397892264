import React from 'react';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';

const LazyNonExistentPathComponent = Loadable({
  loader: () => import(
    './components/NonExistentPath/NonExistentPath'
  ),
  loading: LoadingIcon,
});

const NonExistentPathRoute = (
  <Route
    key="non-existent-page"
    path={'*'}
    component={LazyNonExistentPathComponent}
    exact
  />
);

export default NonExistentPathRoute;
