import {css} from '@emotion/core';
import colors from '../../modules/constants/colors';
import fonts from '../../modules/constants/fonts';
import {
  getValueAtBreakpoint,
  widthOfPoliticianChildContainer,
  viewPortIsSmall,
} from '../../modules/constants/BreakPoints';

const header = (size) => {
  const fontSize = getValueAtBreakpoint(size, {
    xsmall: 30,
    small: 28,
    medium: 32,
    large: 38,
  });

  return css`
    font-size: ${fontSize}px;
    display: flex;
    justify-content: center;
    padding: 15px 0 5px 0;
  `;
};

export default {
  header
};
