import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import withWindowSize from '../higherOrderComponents/withWindowSize';
import mastheadItems from '../../modules/constants/mastheadItems';
import { slide as BurgerMenu } from 'react-burger-menu';
import {Link, useHistory} from 'react-router-dom';
import {rootPath} from '../../modules/constants/routePaths';
import {ENTER} from '../../modules/constants/keyCodes';
import {getCurrentYear} from '../../modules/helpers/dateHelper';
import SocialMediaIcons from '../SocialMediaIcons/SocialMediaIcons';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import styles from './styles.js';

const propTypes = {
  size: PropTypes.string,
};

const Masthead = (props) => {
  const history = useHistory();
  const isTruncatedMenu = props.size === 'large';
  const [burgerMenuIsOpen, setBurgerMenuToOpen] = useState(false);

  const handleBurgerMenuClose = () => {
    setBurgerMenuToOpen(false);
  };

  const handleClickOutsideOfBurgerMenu = (event) => {
    const burgerMenuElement = document.getElementById('masthead-burger-menu');
    if (burgerMenuElement && burgerMenuElement.contains(event.target)) {
      // inside click.
      return;
    }

    // outside click.
    handleBurgerMenuClose();
  };

  useEffect(() => {
    // Bind the event listener when the component mounts.
    document.addEventListener("mousedown", handleClickOutsideOfBurgerMenu);

    // Return function that will be called when the component unmounts.
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutsideOfBurgerMenu);
    };
  }, []);

  // From the package's docs: This keeps your state in sync with the
  // opening/closing of the menu via the default means, e.g. clicking
  // the X, pressing the ESC key etc.
  const isBurgerMenuOpen = (state) => {
    return setBurgerMenuToOpen(state.isOpen);
  };

  const handleMenuItemOnKeyDown = (event, item) => {
    if (event.keyDown === ENTER) {
      if (!isTruncatedMenu) {
        handleBurgerMenuClose();
      }
      history.push(item.url)
    }
  };

  const itemStyle = (
    isTruncatedMenu ?
    styles.truncatedMenuItem :
    styles.burgerMenuItem
  );

  const renderItem = (item) => {
    return (
      item.label === mastheadItems.aboutCreatorLabel ||
      item.label === 'Contact' ?
      <a
        target="_blank"
        href={item.url}
        rel="noopener noreferrer"
        key={item.label}
        onKeyDown={(event) => handleMenuItemOnKeyDown(event, item)}
        css={itemStyle}
        onClick={() => {
          if (!isTruncatedMenu) {handleBurgerMenuClose()}
        }}
      >
        {item.label}
      </a> :
      <Link
        to={item.url}
        onClick={() => {
          if (!isTruncatedMenu) {
            handleBurgerMenuClose()
          }
        }}
        onKeyDown={(event) => handleMenuItemOnKeyDown(event, item)}
        css={itemStyle}
        key={item.label}
      >
        {item.label}
      </Link>
    );
  }

  const truncatedMenu = (
    <div css={styles.mastheadMenu}>
      {_.map(mastheadItems.truncatedMenu, (item) => {
        return renderItem(item);
      })}
    </div>
  );

  const burgerMenu = (
    <BurgerMenu
      id={'masthead-burger-menu'}
      right
      styles={styles.burgerMenu}
      isOpen={burgerMenuIsOpen}
      onStateChange={(state) => isBurgerMenuOpen(state)}
    >
      {
        _.map(mastheadItems.burgerMenu, (item) => {
          return renderItem(item);
        })
      }

      <div css={styles.lowerContent} tabIndex={-1}>
        <SocialMediaIcons
          size={props.size}
          key={'social-media-icons-in-masthead'}
          section={"masthead"}
        />
        <div css={styles.copyrightItem}>
          ©{getCurrentYear()} Categorized.io
        </div>
      </div>
    </BurgerMenu>
  );

  return (
    <div css={styles.container(props.size)}>
      <Link
        css={styles.icon}
        to={rootPath}
      >
        <span css={styles.categorizedText}>CATEGORIZED</span>
        <span css={styles.tweetsText}>TWEETS</span>
      </Link>
      {props.size === 'large' ? truncatedMenu : burgerMenu }
    </div>
  );
};

const mastheadWithWindowSize = withWindowSize(Masthead);
export default mastheadWithWindowSize;
