import {css} from '@emotion/core';
import colors from '../../modules/constants/colors';
import fonts from '../../modules/constants/fonts';
import {
  getValueAtBreakpoint,
  viewPortIsSmall,
  widthOfPoliticianChildContainer,
} from '../../modules/constants/BreakPoints';

const formHeightPadding = 7;
const formWidthPadding = 11;

const inputField = (size) => {
  const fontSize = getValueAtBreakpoint(size, {
    xsmall: 15,
    small: 15,
    medium: 15,
    large: 15,
  });
  const width = getValueAtBreakpoint(size, {
    xsmall: 140,
    small: 140,
    medium: 150,
    large: 150,
  });

  return css`
    font-family: ${fonts.notoSans};
    font-size: ${fontSize}px;
    width: ${width}px;
    border: none;
    padding: 5px 0 5px 11px;
    margin-left: 5px;
  `;
};

const mapIcon = (size) => {
  return css`
    color: ${colors.black};
  `;
};

const zipCodeForm = (size) => {
  const marginTopAndBottom = getValueAtBreakpoint(size, {
    xsmall: 23,
    small: 20,
    medium: 30,
    large: 30,
  });

  return css`
    display: flex;
    align-items: center;
    margin: ${marginTopAndBottom}px 0;
  `;
};

const submitButton = (size) => {
  const fontSize = getValueAtBreakpoint(size, {
    xsmall: 20,
    small: 20,
    medium: 20,
    large: 20,
  });

  return css`
    color: ${colors.white};
    background-color: ${colors.violet};
    font-size: ${fontSize}px;
    font-weight: bold;
    font-family: ${fonts.notoSans};
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    padding: ${formHeightPadding}px ${formWidthPadding}px;
    cursor: pointer;
    height: 45px;
    border: none;
  `;
};

const inputSection = (size) => {
  return css`
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
    display: flex;
    padding: ${formHeightPadding}px ${formWidthPadding}px;
    background-color: ${colors.white};
    height: 45px;
    align-items: center;
  `;
};

export default {
  inputSection,
  submitButton,
  zipCodeForm,
  mapIcon,
  inputField,
};
