import _ from 'lodash';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {ESCAPE} from '../../modules/constants/keyCodes.js';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import styles from './styles.js';

const propTypes = {
  size: PropTypes.string,
  onZipCodeSubmit: PropTypes.func.isRequired,
};

const ZipCodeForm = (props) => {
  const {size, onZipCodeSubmit} = props;
  const [zipCode, updateZipCode] = useState('');

  const onZipcodeFieldChange = (event) => {
    updateZipCode(event.target.value);
  };

  const onKeyDownForInputField = (event) => {
    if (event.keyCode === ESCAPE) {
      updateZipCode('');
    }
  }

  return (
    <form css={styles.zipCodeForm(size)}>
      <div css={styles.inputSection(size)}>
        <i
          className={'fas fa-map-marker-alt fa-2x'}
          css={styles.mapIcon(size)}
        />
        <input
          aria-label="Search Using a ZipCode"
          autoComplete={'off'}
          css={styles.inputField(size)}
          maxLength={5}
          onChange={onZipcodeFieldChange}
          onSubmit={(event) => onZipCodeSubmit(event, zipCode)}
          onKeyDown={onKeyDownForInputField}
          placeholder={'Your ZIP Code'}
          type="text"
          value={zipCode}
        >
        </input>
      </div>
      <button
        css={styles.submitButton(size)}
        onClick={(event) => onZipCodeSubmit(event, zipCode)}
      >
        Go
      </button>
    </form>
  );
};

ZipCodeForm.propTypes = propTypes;
export default ZipCodeForm;
