// Run this example by adding <%= javascript_pack_tag 'index' %> to the head
// of your layout file, like app/views/layouts/application.html.erb.

import React from "react";
import {BrowserRouter} from 'react-router-dom';
import { render } from "react-dom";
import App from "../components/App";
import "core-js/stable";
import "regenerator-runtime/runtime";
import {createBrowserHistory} from 'history';

// Uncomment to copy all static images under ../images to the output folder and
// reference them with the image_pack_tag helper in views
// (e.g <%= image_pack_tag 'rails.png' %>) or the `imagePath` JavaScript
// helper below.
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// export {imagePath};

// Support component names relative to this directory.
// All of our React components shall go to app/javascript/components/ directory.
// This makes the react components available to our rails app.
// const componentRequireContext = require.context("components", true, /\.jsx?$/);
const componentRequireContext = require.context("../components", true);

window.componentRequireContext = componentRequireContext;
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

const history = createBrowserHistory();

document.addEventListener("DOMContentLoaded", () => {
  render(
    <BrowserRouter history={history}>
      <App />
    </BrowserRouter >,
    document.body.appendChild(document.createElement("div"))
  );
});
