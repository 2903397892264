import {css} from '@emotion/core';
import fonts from '../../modules/constants/fonts';
import colors from '../../modules/constants/colors';

const container = (size) => {
  let justifyContent = 'normal';
  if (size === 'large') {
    justifyContent = 'space-around';
  }
  return css`
    background-color: ${colors.navyBlue};
    height: 64px;
    font-family: ${fonts.didactGothic};
    display: flex;
    align-items: center;
    justify-content: ${justifyContent};
    padding: 0 20px;
    position: fixed;
    width: 100%;
    z-index: 10;
  `;
};

const icon = css`
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none !important;
`;

const categorizedText = css`
  color: ${colors.lightBlue};
`;

const tweetsText = css`
  color: ${colors.white};
`;

const mastheadMenu = css`
  display: flex;
  color: ${colors.mastheadGray};
  font-size: 20px;
  justify-content: space-around;
`;

const burgerMenuItem = css`
  display: block !important;
  text-decoration: none !important;
  color: ${colors.lightBlue};

  &:hover {
    color: ${colors.white};
  }

  &:focus {
    color: ${colors.white};
  }
`;

const lowerContent = css`
  ${burgerMenuItem};
  margin-top: 4rem;
`;

const copyrightItem = css`
  color: ${colors.lightGray};
`;

const truncatedMenuItem = css`
  margin-right: 25px;
  text-decoration: none !important;
  color: ${colors.mastheadGray};

  &:hover {
    color: ${colors.white};
  }

  &:focus {
    color: ${colors.white};
  }

  &:active {
    color: ${colors.mastheadGray};
  }
`;

const burgerMenu = {
  bmBurgerButton: {
    position: 'absolute',
    width: '40px',
    height: '35px',
    top: '15px',
    right: '20px',
    left: 'initial',
  },
  bmBurgerBars: {
    background: colors.lightBlue,
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    bottom: '5px',
    width: '250px',
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: colors.lightBlue,
  },
  bmItem: {
    display: 'inline-block',
    marginBottom: '15px',
    padding: '.5rem .8rem',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    width: 'auto',
  }
}

export default {
  copyrightItem,
  lowerContent,
  container,
  icon,
  categorizedText,
  tweetsText,
  mastheadMenu,
  truncatedMenuItem,
  burgerMenuItem,
  burgerMenu,
};
