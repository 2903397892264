import {css} from '@emotion/core';
import colors from '../../modules/constants/colors';
import {
  getValueAtBreakpoint,
} from '../../modules/constants/BreakPoints';

const contactContainer = (size, section) => {
  let fontSize = 2.5;

  if (section === 'footer') {
    fontSize = getValueAtBreakpoint(size, {
      'xsmall': 2,
      'small': 2,
      'medium': 2.1,
      'large': 2.1,
    });
  }

  return css`
    font-size: ${fontSize}rem;
    margin-bottom: ${section === 'masthead' ? .5 : 0}rem;
  `;
};

const contactIcon = (size, contactName, section) => {
  let color = colors.twitterBlue;

  if (contactName == 'instagram') {
    color = '#fb3958';
  } else if (contactName == 'email'){
    color = '#337ab7';
  }

  if (section === 'masthead') {
    color = colors.white;
  }

  const hoverColor = (
    section === 'masthead' ?
    colors.lightBlue :
    colors.white
  );

  return css`
    color: ${color};

    &:hover {
      color: ${hoverColor};
    };
  `;
};

const anchorLinkWrapperForContact = (size, section) => {
  const backgroundColor = (
    section === 'masthead' ?
    'inherit' :
    colors.faintGray
  );

  const padding = (
    section === 'footer' ?
    '.5rem .8rem' :
    '0'
  );

  const margin = (
    section === 'footer' ?
    0 :
    '0 1rem 0 0'
  );

  const outlineOffset = (
    section === 'footer' ?
    '-2' :
    '1'
  );

  return css`
    padding: ${padding};
    margin: ${margin};

    &:hover {
      background-color: ${backgroundColor};
    };

    &:focus {
      outline-offset: ${outlineOffset}px;
    };
  `;
};

export default {
  anchorLinkWrapperForContact,
  contactContainer,
  contactIcon,
};
