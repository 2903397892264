import React from 'react';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';
import {aboutPath} from '../../modules/constants/routePaths';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';

const LazyAboutComponent = Loadable({
  loader: () => import(
    './components/About/About'
  ),
  loading: LoadingIcon,
});

const AboutRoute = (
  <Route
    key="about"
    path={aboutPath}
    component={LazyAboutComponent}
    exact
  />
);

export default AboutRoute;
