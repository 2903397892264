import {css} from '@emotion/core';
import colors from '../../modules/constants/colors';
import fonts from '../../modules/constants/fonts';
import {
  getValueAtBreakpoint,
  widthOfPoliticianChildContainer,
  viewPortIsSmall,
} from '../../modules/constants/BreakPoints';

const cards = (size) => {
  const gridTemplateColumns = getValueAtBreakpoint(size, {
    'xsmall': '1fr 1fr',
    'small': '1fr 1fr',
    'medium': '1fr 1fr 1fr 1fr',
    'large': '1fr 1fr 1fr 1fr 1fr',
  });

  const gridVertical = getValueAtBreakpoint(size, {
    xsmall: 3,
    small: 3,
    medium: 25,
    large: 25,
  });

  return css`
    display: grid;
    grid-template-columns: ${gridTemplateColumns};
    grid-gap: 35px ${gridVertical}px;
    justify-items: ${viewPortIsSmall ? 'center' : 'normal'};
    margin: 3rem 0 10rem 0;
  `;
};

export default {
  cards
};
