import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import styles from './styles.js';

const propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
};

const PoliticianCardsHeader = (props) => {
  const {children, size} = props;

  return (
    <div css={styles.header(size)}>
      {children}
    </div>
  );
};

PoliticianCardsHeader.propTypes = propTypes;
export default PoliticianCardsHeader;
