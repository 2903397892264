import SCREENSIZE from './ScreenSize';
const BREAKPOINTS = {};

/**
 * These numbers represent ranges.
 * xsmall = 0-425
 * small = 426-768
 * medium = 739-1023
 * large = 1024-1440 and up
 */
BREAKPOINTS[SCREENSIZE.xsmall] = 425;
BREAKPOINTS[SCREENSIZE.small] = 767;
BREAKPOINTS[SCREENSIZE.medium] = 1023;
BREAKPOINTS[SCREENSIZE.large] = 1440;

export const getValueAtBreakpoint = (size, {xsmall, small, medium, large}) => {
  const valueAtBreakpoint = {
    xsmall,
    small,
    medium,
    large
  };

  return valueAtBreakpoint[size];
};

export const viewPortIsSmall = (size) => {
  return (
    size === 'xsmall' ||
    size === 'small'
  );
};

export const widthOfPoliticianChildContainer = (size) => {
  const widthValues = {
    xsmall: 395,
    small: 721,
    medium: 961,
    large: 967,
  };

  return widthValues[size];
}

export default BREAKPOINTS;
