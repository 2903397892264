import {css} from '@emotion/core';
import fonts from '../../modules/constants/fonts';
import colors from '../../modules/constants/colors';
import {
  getValueAtBreakpoint,
} from '../../modules/constants/BreakPoints';

const container = (size) => {
  return css`
    background-color: ${colors.faintGreen};
    color: ${colors.navyBlue};
    bottom: 0;
    margin: auto;
    width: 100%;
    font-family: ${fonts.notoSans};
    position: fixed;
  `;
};

const childContainer = (size) => {
  const fontSize = getValueAtBreakpoint(size, {
    xsmall: 1.5,
    small: 1.5,
    medium: 1.5,
    large: 1.5,
  });
  const maxWidth = getValueAtBreakpoint(size, {
    xsmall: 500,
    small: 500,
    medium: 961,
    large: 967,
  });

  return css`
    width: 100%;
    max-width: ${maxWidth}px;
    font-size: ${fontSize}rem;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    padding: 1rem 0;
  `;
};

const footerItem = (size) => {
  return css`
    color: ${colors.navyBlue};
    padding: .5rem .7rem;
  `;
};

export default {
  container,
  childContainer,
  footerItem,
};
