import React from "react";
import {Switch} from 'react-router-dom';

/**
 * Ported comment: This breaks up the app into separate "page" level routes.
 *
 * Each route should use dynamic imports so webpack can bundle them as a code
 * split point. This minimizes the app's initial bundle size and causes users
 * to incrementally download the app one route at a time via asynchronous lazy
 * loading.
*/

import PoliticianRoute from './Politician/PoliticianRoute';
import SearchRoute from './Search/SearchRoute';
import HomeRoute from './Home/HomeRoute';
import AboutRoute from './About/AboutRoute';
import DonateRoute from './Donate/DonateRoute';
import TermsOfServiceRoute from './TermsOfService/TermsOfServiceRoute';
import NonExistentPathRoute from './NonExistentPath/NonExistentPathRoute';
// import US2020ElectionsRoute from './US2020Elections/US2020ElectionsRoute';

export default (
  <Switch>
    {HomeRoute}
    {PoliticianRoute}
    {SearchRoute}
    {TermsOfServiceRoute}
    {AboutRoute}
    {DonateRoute}
    /*
      ORDER HERE MATTERS. THE 'CATCH-ALL' NonExistentPathRoute BELOW SHOULD
      **REMAIN LAST** IN THIS LIST OF ROUTES.
    */
    {NonExistentPathRoute}
  </Switch>
);
