const colors = {
  backgroundGray: '#f6f5f5',
  navyBlue: '#064e64',
  violet: '#e1186f',
  lightGray: '#AAB4B4',
  veryLightGray: '#c4c4c4',
  lightBlue: '#9ee4fa',
  white: '#FFFFFF',
  mastheadGray: '#bcbcbe',
  twitterBlue: '#58c9ed',
  darkGray: '#909090',
  black: '#000000',
  teal: '#07576f',
  faintGray: '#E9EEEE',
  peach: '#FADBD8',
  placeholderGray: '#7f7f7f',
  faintGreen: '#F8F8F8',
};

export default colors;
