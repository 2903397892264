import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import PoliticianCard from "../PoliticianCard/PoliticianCard";

/** @jsx jsx */
import { jsx } from '@emotion/core'
import styles from './styles.js';

const propTypes = {
  politicians: PropTypes.array.isRequired,
  size: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  searchExecuted: PropTypes.bool.isRequired,
};

const defaultProps = {
  searchExecuted: false,
};

const PoliticianCards = (props) => {
  const {politicians, size, isLoading, history, searchExecuted} = props;

  return (
    <div css={styles.cards(size)}>
      {_.map(politicians, (politician, index) => {
        return (
          <PoliticianCard
            politician={politician}
            isLoading={isLoading}
            size={size}
            history={history}
            key={`${index}-${politician.id}`}
            searchExecuted={searchExecuted}
          />
        );
      })}
    </div>
  );
};

PoliticianCards.propTypes = propTypes;
PoliticianCards.defaultProps = defaultProps;
export default PoliticianCards;
