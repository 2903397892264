import React from "react"
import Routes from "../routes/routes";
import Masthead from './Masthead/Masthead';
import Footer from './Footer/Footer';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Router } from 'react-router';
import {createBrowserHistory} from 'history';
import PoliticianRoute from '../routes/Politician/PoliticianRoute';
import HomeRoute from '../routes/Home/HomeRoute';

// Our date utility library:
import MomentUtils from '@date-io/moment';

const history = createBrowserHistory();

export default props => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Masthead />
        {Routes}
      <Footer />
    </MuiPickersUtilsProvider>
  );
}
