import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import BlankAvatar from 'images/blank_avatar/blank_avatar.jpg';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import styles from './styles.js';

const propTypes = {
  politician: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  size: PropTypes.string,
  isPoliticianCard: PropTypes.bool,
};

const defaultProps = {
  isPoliticianCard: false,
};

const PoliticianImage = (props) => {
  const {politician, size, history, isPoliticianCard} = props;
  const handleImageClick = () => {
    history.push(`/politicians/${politician.slug}`);
  };

  return (
    <img
      src={_.get(politician, 'image') || BlankAvatar}
      alt={`An image of ${_.get(politician, 'name')}.`}
      css={styles.image(size, isPoliticianCard)}
      onClick={handleImageClick}
    />
  );
};

PoliticianImage.propTypes = propTypes;
PoliticianImage.defaultPropes = defaultProps;
export default PoliticianImage;
