import React from 'react';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';
import {termsOfServicePath} from '../../modules/constants/routePaths';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';

const LazyTermsOfServiceComponent = Loadable({
  loader: () => import(
    './components/TermsOfService/TermsOfService'
  ),
  loading: LoadingIcon,
});

const TermsOfServiceRoute = (
  <Route
    key="terms-of-service"
    path={termsOfServicePath}
    component={LazyTermsOfServiceComponent}
    exact
  />
);

export default TermsOfServiceRoute;
