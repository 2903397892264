import React from 'react';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';
import {donatePath} from '../../modules/constants/routePaths';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';

const LazyDonateComponent = Loadable({
  loader: () => import(
    './components/Donate/Donate'
  ),
  loading: LoadingIcon,
});

const DonateRoute = (
  <Route
    key="donate"
    path={donatePath}
    component={LazyDonateComponent}
    exact
  />
);

export default DonateRoute;
