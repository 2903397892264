import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import styles from './styles.js';

const propTypes = {
  size: PropTypes.string,
  section: PropTypes.string.isRequired,
};

const SocialMediaIcons = (props) => {
  const {size, section} = props;

  const contactMediums = [
    {

      name: 'twitter',
      icon: 'fab fa-twitter-square',
      url: 'http://www.twitter.com/categorized_io',
    },
    {
      name: 'instagram',
      icon: 'fab fa-instagram-square',
      url: 'http://www.instagram.com/categorized_io/',
    },
    {
      name: 'email',
      icon: 'fas fa-envelope-open-text',
      url: 'mailto:categorizedtweets@gmail.com',
    },
  ];

  return (
    <div
      css={styles.contactContainer(size, section)}
      key={'contact-icons-in-footer'}
    >
      {_.map(contactMediums, (medium) => {
        return (
          <a
            target="_blank"
            href={medium.url}
            rel="noopener noreferrer"
            css={styles.anchorLinkWrapperForContact(size, section)}
            key={`footer-${medium.name}-icon`}
            alt={`A ${medium.name} icon.`}
          >
            <i
              className={medium.icon}
              css={styles.contactIcon(size, medium.name, section)}
            />
          </a>
        );
      })}
    </div>
  );
};

SocialMediaIcons.propTypes = propTypes;
export default SocialMediaIcons;
