import {css} from '@emotion/core';

const image = (size, isPoliticianCard) => {
  let length;
  if (isPoliticianCard) {
    if (size == 'medium' || size === 'large') {
      length = 135;
    } else {
      length = 96;
    }
  } else {
    if (size == 'medium' || size === 'large') {
      length = 200;
    } else if (size === 'xsmall') {
      length = 86;
    } else {
      length = 96;
    }
  }

  return css`
    border-radius: 100%;
    cursor: pointer;
    height: ${length}px;
    width: ${length}px;
  `;
}

export default {
  image,
};
