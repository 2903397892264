import {css} from '@emotion/core';
import fonts from '../../modules/constants/fonts';
import colors from '../../modules/constants/colors';
import {
  widthOfPoliticianChildContainer,
  getValueAtBreakpoint,
} from '../../modules/constants/BreakPoints';

const container = (size) => {
  const fontSize = getValueAtBreakpoint(size, {
    xsmall: 1.8,
    small: 2,
    medium: 2.5,
    large: 2.5,
  });

  const paddingLeftRight = getValueAtBreakpoint(size, {
    xsmall: 2,
    small: 2,
    medium: 1,
    large: 1,
  });

  return css`
    font-family: ${fonts.notoSans};
    width: 100%;
    max-width: ${widthOfPoliticianChildContainer(size)}px;
    padding: 15rem ${paddingLeftRight}rem;
    font-size: ${fontSize}rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;
};

const button = (size) => {
  const fontSize = getValueAtBreakpoint(size, {
    xsmall: 1.8,
    small: 2,
    medium: 2,
    large: 2,
  });

  return css`
    background-color: ${colors.violet};
    color: ${colors.white};
    padding: 1rem 1rem;
    font-size: ${fontSize}rem;
    margin-top: 2rem;
    border-radius: .5rem;

    &:hover {
      text-decoration: none;
    }
  `;
};

export default {
  container,
  button,
};
