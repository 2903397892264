import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {searchPath} from '../../modules/constants/routePaths';
import {ENTER, ESCAPE, SPACE} from '../../modules/constants/keyCodes';
import SearchByMenu from '../../routes/Search/components/SearchByMenu/SearchByMenu';
import {searchTypeOptions} from '../../routes/Search/constants';
import Alert from '@material-ui/lab/Alert';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import styles from './styles.js';

const propTypes = {
  size: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  query: PropTypes.string,
  searchType: PropTypes.string.isRequired,
};

const SearchForm = (props) => {
  const {
    size,
    history,
    query,
    searchType,
  } = props;

  const isSearchPage = history.location.pathname === searchPath;

  // TO-DO: Build native zipcode-alert message. Don't use Material UI.
  const zipCodeErrorAdvisory = (
    <Alert
      css={styles.zipCodeErrorAlert(size)}
      severity="error"
    >
      Be sure to submit a 5-digit ZIP code.
    </Alert>
  );

  const [zipCodeError, setZipCodeAlert] = useState(null);

  let placeholder = 'Your ZIP Code';
  if (searchType === 'address') {
    placeholder = "Your Address";
  } else if (searchType === 'politician') {
    placeholder = "Politician's Name";
  }

  const needleValue = query || '';
  const [needle, setNeedle] = useState(needleValue);

  const onNeedleChange = (event) => {
    setNeedle(event.target.value);
  };
  const maxLength = searchType === 'zipcode' ? 5 : 200;
  const mapIcon = (
    <i
      className={'fas fa-map-marker-alt fa-2x'}
      css={styles.mapIcon(size)}
    />
  );
  const personIcon = (
    <i
      className={'fas fa-user fa-2x'}
      css={styles.personIcon(size)}
    />
  );

  let inputFieldIcon = mapIcon;
  if (searchType === 'politician') {
    inputFieldIcon = personIcon;
  }

  const initiateSearch = (searchType, needle) => {
    history.push(`${searchPath}?type=${searchType}&query=${needle}`);
  };

  const handleQuerySubmission = (event) => {
    event.preventDefault();

    if (zipCodeError) {
      setZipCodeAlert(null);
    }

    if (needle) {
      if (searchType === 'zipcode') {
        // Display the zipcode error if the zipcode format is invalid.
        const validZipCodeFormat = /^\d{5}$/.test(needle);
        if (!validZipCodeFormat) {
          setZipCodeAlert(zipCodeErrorAdvisory);
        } else {
          initiateSearch(searchType, needle);
        }
      } else {
        initiateSearch(searchType, needle);
      }
    } else {
      const searchTypeParam = searchType || 'zipcode';
      initiateSearch(searchTypeParam, '');
    }
  };

  const handleOnKeyDownForInputField = (event) => {
    if (event.keyCode === ENTER) {
      handleQuerySubmission(event);
    } else if (event.keyCode === ESCAPE) {
      setNeedle('');
    }
  };

  return (
    <div css={styles.container(size)}>
      <div>
        {
          isSearchPage &&
          <SearchByMenu
            searchType={searchType}
            size={size}
            history={history}
            setNeedle={setNeedle}
            setZipCodeAlert={setZipCodeAlert}
          />
        }
        <form css={styles.form(size)}>
          <div css={styles.leftSideOfForm(size)}>
            {inputFieldIcon}
            <input
              aria-label="Search Categorized"
              autoComplete="off"
              css={styles.searchInputField(size)}
              id={'search-page-input-field'}
              maxLength={maxLength}
              onChange={(event) => onNeedleChange(event)}
              onKeyDown={(event) => handleOnKeyDownForInputField(event)}
              onSubmit={(event) => handleQuerySubmission(event)}
              placeholder={placeholder}
              type="text"
              value={needle}
            />
          </div>
          <button
            css={styles.searchButton(size)}
            onClick={(event) => handleQuerySubmission(event)}
          >
            Search
          </button>
        </form>
        {zipCodeError}
      </div>
    </div>
  );
};

SearchForm.propTypes = propTypes;
export default SearchForm;
