import React from 'react';
import Loadable from 'react-loadable';
import {Route} from 'react-router-dom';
import {politicianPath} from '../../modules/constants/routePaths';
import LoadingIcon from '../../components/LoadingIcon/LoadingIcon';

const LazyPoliticianContainer = Loadable({
  loader: () => import(
    './containers/PoliticianContainer/PoliticianContainer'
  ),
  loading: LoadingIcon,
});

const PoliticianRoute = (
  <Route
    key="politician"
    path={`${politicianPath}/:politicianSlug?`}
    component={LazyPoliticianContainer}
    exact
  />
);

export default PoliticianRoute;
