const truncatedMenu = [
  {
    label: 'Search',
    url: '/search',
  },
  {
    label: 'FAQs',
    url: '/about',
  },
  {
    label: 'Donate',
    url: '/donate',
  },
];

const aboutCreatorLabel = 'About the Creator, Diana Eromosele';

const burgerMenu = [
  ...truncatedMenu,
  {
    label: aboutCreatorLabel,
    url: 'http://www.linkedin.com/in/dianaeromosele/',
  },
  {
    label: 'Contact',
    url: 'mailto:categorizedtweets@gmail.com',
  },
  {
    label: 'Terms of Service',
    url: '/terms-of-service',
  },
];

export default {
  truncatedMenu,
  burgerMenu,
  aboutCreatorLabel,
}
