import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {
  ENTER,
  ESCAPE,
  TAB,
  DOWN_ARROW,
  SPACE,
} from '../../../../modules/constants/keyCodes';
import {searchPath} from '../../../../modules/constants/routePaths';
import {searchTypeOptions} from '../../constants';
import {Menu} from '@material-ui/core';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import styles from './styles.js';

const propTypes = {
  searchType: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  setNeedle: PropTypes.func.isRequired,
  setZipCodeAlert: PropTypes.func.isRequired,
};

const SearchByMenu = (props) => {
  const {size, history, searchType, setNeedle, setZipCodeAlert} = props;
  const [anchorElement, setAnchorElementForMenu] = useState(null);
  const [currentMenuItemIndex, setCurrentMenuItemIndex] = useState(-1);

  const handleClose = () => {
    setAnchorElementForMenu(null);
    setCurrentMenuItemIndex(-1);
  };

  const handleEventToCloseTheMenu = (event) => {
    if (event.keyCode === TAB || event.keyCode === DOWN_ARROW) {
      // The menu is sensitive to certain key events that the user may be
      // using to navigate through the menu. Prevent the browser's default
      // behavior for these two key events.
      event.preventDefault();
    } else {
      handleClose();
    }
  };

  const handleOptionClick = (option) => {
    const newSearchTypeParam = _.find(searchTypeOptions, ['menuItem', option]).searchType;
    setNeedle('');
    setZipCodeAlert(null);
    history.push(`${searchPath}?type=${newSearchTypeParam}`);

    handleClose();
  };

  const handleOptionKeyDown = (event, option) => {
    if (event.keyCode === ENTER || event.keyCode === SPACE) {
      event.preventDefault();
      handleOptionClick(option);
    } else if (event.keyCode === TAB) {
      const menuOptionElements = Array.from(document.querySelectorAll(".search-type-option"));

      if (_.isEmpty(menuOptionElements)) {
        return;
      }

      const indexOfLastOption = menuOptionElements.length - 1;
      // If the user presses "Tab" on the last menu item, close the menu
      // and re-set the menu item index so that the user can start from the
      // top when the menu is re-opened.
      if (currentMenuItemIndex === indexOfLastOption) {
        handleClose();
        setCurrentMenuItemIndex(-1);
      } else {
        // Else, apply focus to the next item in the list and push this
        // intelligence to local state.
        menuOptionElements[currentMenuItemIndex + 1].focus();
        setCurrentMenuItemIndex(currentMenuItemIndex + 1);
      }
    }
  };

  const menuOptions = _.map(searchTypeOptions, 'menuItem');

  const selectedMenuItem = (
    _.find(searchTypeOptions, ['searchType', searchType]).menuItem ||
    zipCodeMenuItem
  );

  return (
    <div css={styles.menu(size)}>
      <span css={styles.searchText}>Search</span>
      <button
        onClick={(event) => setAnchorElementForMenu(event.currentTarget)}
        css={styles.selectionButton(size)}
      >
        <span>
          {selectedMenuItem}
        </span>
        <i
          className={'fas fa-angle-down fa-2x'}
          css={styles.angleDownIcon(size)}
        />
      </button>
      <Menu
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleEventToCloseTheMenu}
      >
        {_.map(menuOptions, (option) => {
          const isSelected = option === selectedMenuItem;

          return (
            <li
              onClick={() => handleOptionClick(option)}
              onKeyDown={(event) => handleOptionKeyDown(event, option)}
              key={option}
              css={styles.menuOption(size, isSelected)}
              tabIndex={0}
              className={'search-type-option'}
            >
              {option}
            </li>
          );
        })}
      </Menu>
    </div>
  );
};

SearchByMenu.propTypes = propTypes;
export default SearchByMenu;
