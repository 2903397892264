import {css} from '@emotion/core';
import {
  getValueAtBreakpoint,
} from '../../modules/constants/BreakPoints';
import colors from '../../modules/constants/colors';
import fonts from '../../modules/constants/fonts';

const politicianCard = (size) => {
  const width = getValueAtBreakpoint(size, {
    'xsmall': 170,
    'small': 180,
    'medium': 180,
    'large': 180,
  });

  return css`
    width: ${width}px;
    font-family: ${fonts.notoSans};
    padding: 10px 15px;

    && {
      text-decoration: none;
      color: ${colors.black};
    };

    &:hover {
      box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.24);
    };
  `;
};

const politicianName = (size) => {
  const fontSize = getValueAtBreakpoint(size, {
    'xsmall': 21,
    'small': 20,
    'medium': 20,
    'large': 20,
  });

  return css`
    font-weight: bold;
    font-size: ${fontSize}px;
    color: ${colors.violet};
    margin-top: 15px;
  `;
};

const politicianParty = (size) => {
  const fontSize = getValueAtBreakpoint(size, {
    'xsmall': 18,
    'small': 15,
    'medium': 15,
    'large': 15,
  });

  return css`
    font-size: ${fontSize}px;
    margin-bottom: 15px;
  `;
};

const politicianTitle = (size) => {
  const fontSize = getValueAtBreakpoint(size, {
    'xsmall': 18,
    'small': 15,
    'medium': 15,
    'large': 15,
  });

  return css`
    font-size: ${fontSize}px;
  `;
};

export default {
  politicianCard,
  politicianName,
  politicianParty,
  politicianTitle,
}
