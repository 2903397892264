import {css} from '@emotion/core';
import colors from '../../modules/constants/colors';
import fonts from '../../modules/constants/fonts';
import {
  getValueAtBreakpoint,
  widthOfPoliticianChildContainer,
  viewPortIsSmall,
} from '../../modules/constants/BreakPoints';

const container = (size) => {
  const paddingTop = getValueAtBreakpoint(size, {
    xsmall: 85,
    small: 85,
    medium: 120,
    large: 120,
  });

  return css`
    max-width: ${widthOfPoliticianChildContainer(size)}px;
    font-family: ${fonts.notoSans};
    margin: auto;
    padding: ${paddingTop}px 16px 0 16px;
    display: flex;
    flex-direction: column;
    align-items: ${viewPortIsSmall(size) ? 'normal' : 'center'};
  `;
};

const form = (size) => {
  return css`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-direction: ${viewPortIsSmall(size)? 'column' : 'row'}
  `;
};

const searchInputField = (size) => {
  const width = getValueAtBreakpoint(size, {
    'xsmall': '100%',
    'small': '100%',
    'medium': '450px',
    'large': '450px',
  });

  const fontSize = getValueAtBreakpoint(size, {
    'xsmall': 1.8,
    'small': 1.8,
    'medium': 2.4,
    'large': 2.4,
  });

  return css`
    width: ${width};
    font-size: ${fontSize}rem;
    color: ${colors.navyBlue};
    caret-color: ${colors.navyBlue};
    outline: 0;
    border-width: 0 0 2px;
    font-weight: 700;
    min-width: 0;
    text-overflow: ellipsis;
    margin-right: ${viewPortIsSmall(size) ? 0 : 11}px;
    padding-left: 10px;
    border-bottom: 3px solid ${colors.navyBlue};
    background-color: ${colors.backgroundGray};

    &::placeholder {
      color: ${colors.placeholderGray};
    }
  `;
};

const searchButton = (size) => {
  const width = getValueAtBreakpoint(size, {
    'xsmall': 300,
    'small': 100,
    'medium': 100,
    'large': 100,
  });

  const paddingHorizontal = getValueAtBreakpoint(size, {
    'xsmall': 0,
    'small': 0,
    'medium': 15,
    'large': 15,
  });

  return css`
    background-color: ${colors.violet};
    color: ${colors.white};
    font-weight: bold;
    font-size: 2rem;
    border: none;
    border-radius: 8px;
    padding: 10px ${paddingHorizontal}px;
  `;
};

const mapIcon = (size) => {
  const paddingTop = getValueAtBreakpoint(size, {
    xsmall: 0,
    small: 0,
    medium: 9,
    large: 9,
  });

  const paddingBottom = getValueAtBreakpoint(size, {
    xsmall: 3,
    small: 5,
    medium: 0,
    large: 0,
  });

  return css`
    border-bottom: 3px solid ${colors.navyBlue};
    padding-top: ${paddingTop}px;
    padding-bottom: ${paddingBottom}px;
  `;
};

const personIcon = (size) => {
  const paddingTop = getValueAtBreakpoint(size, {
    xsmall: 0,
    small: 0,
    medium: 9,
    large: 9,
  });

  const paddingBottom = getValueAtBreakpoint(size, {
    xsmall: 5,
    small: 5,
    medium: 0,
    large: 0,
  });

  return css`
    border-bottom: 3px solid ${colors.navyBlue};
    padding-top: ${paddingTop}px;
    padding-bottom: ${paddingBottom}px;
  `;
};

const leftSideOfForm = (size) => {
  const marginBottom = getValueAtBreakpoint(size, {
    xsmall: 25,
    small: 25,
    medium: 0,
    large: 0,
  });

  return css`
    display: flex;
    justify-content: center;
    margin-bottom: ${marginBottom}px;
  `;
};

const zipCodeErrorAlert = (size) => {
  const fontSize = getValueAtBreakpoint(size, {
    xsmall: 1.5,
    small: 1.5,
    medium: 1.5,
    large: 1.5,
  });

  return css`
    && {
      border: 1px solid red;
      width: max-content;
      font-size: ${fontSize}rem;
      margin-bottom: 10px;
    }
  `;
};

export default {
  zipCodeErrorAlert,
  leftSideOfForm,
  personIcon,
  mapIcon,
  container,
  searchInputField,
  form,
  searchButton,
};
