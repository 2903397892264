import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import withWindowSize from '../higherOrderComponents/withWindowSize';
import mastheadItems from '../../modules/constants/mastheadItems';
import { slide as BurgerMenu } from 'react-burger-menu';
import {Link, useHistory} from 'react-router-dom';
import {rootPath, termsOfServicePath} from '../../modules/constants/routePaths';
import {ENTER} from '../../modules/constants/keyCodes';
import ExternalLink from '../ExternalLink/ExternalLink';
import SocialMediaIcons from '../SocialMediaIcons/SocialMediaIcons';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import styles from './styles.js';

const propTypes = {
  size: PropTypes.string,
};

const Footer = (props) => {
  const history = useHistory();
  const {size} = props;

  const footerItems = [
    {
      label: 'Terms of Service',
      url: '/terms-of-service',
    },
    {
      label: 'Contact Icons',
    },
    {
      label: 'About the Creator',
      url: 'http://www.linkedin.com/in/dianaeromosele/',
    },
    {
      label: 'Search',
      url: '/search',
    },
    {
      label: 'Your Support',
      url: '/donate',
    },
  ];

  if (size !== 'large') {
    _.remove(footerItems, (item) => {
      return (
        item.label === 'Terms of Service' ||
        item.label === 'About the Creator' ||
        item.label === 'Your Support'
      );
    })
  }

  if (size === 'large') {
    _.remove(footerItems, (item) => {
      return (
        item.label === 'Search'
      );
    })
  }

  return (
    <div css={styles.container(props.size)}>
      <div css={styles.childContainer(props.size)}>
        {
          _.map(footerItems, (item) => {
            if (item.label === 'Contact Icons') {
              return (
                <SocialMediaIcons
                  size={size}
                  key={'social-media-icons-in-footer'}
                  section={'footer'}
                />
              );
            } else if (item.label == 'About the Creator') {
              return (
                <ExternalLink
                  text={item.label}
                  url={item.url}
                  styles={styles.footerItem(size)}
                  key={`footer-${item.label}`}
                />
              );
            } else {
              return (
                <Link
                  to={item.url}
                  css={styles.footerItem(size)}
                  key={`footer-${item.label}`}
                >
                  {item.label}
                </Link>
              );
            };
          })
        }
      </div>
    </div>
  );
};

const footerWithWindowSize = withWindowSize(Footer);
export default footerWithWindowSize;
