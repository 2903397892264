import React from 'react';
import PropTypes from 'prop-types';
import SCREENSIZE from '../../modules/constants/ScreenSize';
import BREAKPOINTS from '../../modules/constants/BreakPoints';

const propTypes = {
  /**
   * a function that is passed the props of the component, and
   * the current screen size, passed via withWindowSize
   */
  __mapSizeToProps: PropTypes.func,
};

/**
 * @example
 * export default withWindowSize(<'wrapped component'>)
 */
export default function withWindowSize(WrappedComponent) {
  class WithWindowSize extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        width: null,
        size: null
      };
    }

    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', () => this.updateWindowDimensions());
    }

    componentWillUnmount() {
      window.removeEventListener('resize', () => this.updateWindowDimensions());
      window.removeEventListener('forceUpdate', () => this.updateWindowDimensions());
    }

    updateWindowDimensions() {
      const width = window.innerWidth;
      let size = null;

      if (width <= BREAKPOINTS.xsmall) {
        size = SCREENSIZE.xsmall;
      } else if (width <= BREAKPOINTS.small) {
        size = SCREENSIZE.small;
      } else if (width <= BREAKPOINTS.medium) {
        size = SCREENSIZE.medium;
      } else {
        size = SCREENSIZE.large;
      }

      this.setState({width: window.innerWidth, size: size});
    }

    render() {
      const props = this.props.__mapSizeToProps ?
        this.props.__mapSizeToProps(this.props, this.state.size) :
        this.props;
      return (<WrappedComponent
        size={this.state.size}
        viewportWidth={this.state.width}
        {...props}
      />);
    }
  }

  // Give each instance of the HOC a name related to its wrapped component.
  const wrappedComponentName = WrappedComponent.displayName
    || WrappedComponent.name
    || 'Component';
  WithWindowSize.displayName = `withWindowSize(${wrappedComponentName})`;

  WithWindowSize.propTypes = propTypes;

  return WithWindowSize;
}
