import {css} from '@emotion/core';
import colors from '../../../../modules/constants/colors';
import fonts from '../../../../modules/constants/fonts';
import {
  getValueAtBreakpoint,
  widthOfPoliticianChildContainer,
  viewPortIsSmall,
} from '../../../../modules/constants/BreakPoints';

const menu = (size) => {
  const fontSize = getValueAtBreakpoint(size, {
    'xsmall': 2,
    'small': 2,
    'medium': 2,
    'large': 2,
  });
  const marginBottom = getValueAtBreakpoint(size, {
    'xsmall': 3,
    'small': 3,
    'medium': 1.5,
    'large': 1.5,
  });

  return css`
    display: flex;
    align-items: center;
    font-family: ${fonts.notoSans};
    font-size: ${fontSize}rem;
    font-weight: bold;
    margin-bottom: ${marginBottom}rem;
  `;
};

const searchText = css`
  color: ${colors.navyBlue};
  margin-right: .5rem;
`;

const angleDownIcon = (size) => {
  const fontSize = getValueAtBreakpoint(size, {
    'xsmall': 2,
    'small': 2,
    'medium': 2,
    'large': 2,
  });

  return css`
    font-size: ${fontSize}rem;
    margin-left: .6rem;
  `;
};

const menuOption = (size, isSelected) => {
  const fontSize = getValueAtBreakpoint(size, {
    xsmall: 1.6,
    small: 1.6,
    medium: 1.6,
    large: 1.6,
  });

  return css`
    font-size: ${fontSize}rem;
    font-family: ${fonts.notoSans};
    color: ${colors.black};
    background-color: ${isSelected ? colors.faintGray : null};
    cursor: pointer;
    padding: 6px 16px;

    &:hover {
      color: ${colors.white};
      background-color: ${colors.violet};
    };

    &:focus {
      color: ${colors.white};
      background-color: ${colors.violet};
    };
  `;
};

const selectionButton = (size) => {
  return css`
    display: flex;
    align-items: center;
    color: ${colors.violet};
    border: none;
    padding: 0;
    border-bottom: 3px solid ${colors.violet};
    background-color: ${colors.backgroundGray};
  `;
};


export default {
  searchText,
  selectionButton,
  angleDownIcon,
  menu,
  menuOption,
};
