import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import {politicianPath, searchPath} from '../../modules/constants/routePaths';
import PoliticianImage from '../PoliticianImage/PoliticianImage';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import styles from './styles.js';

const propTypes = {
  size: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  politician: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  searchExecuted: PropTypes.bool.isRequired,
};

const defaultProps = {
  searchExecuted: false,
};

const PoliticianCard = (props) => {
  const {size, politician, isLoading, history, searchExecuted} = props;
  const searchParams = new URLSearchParams(history.location.search);
  const searchType = searchParams.get('type') || 'zipcode';
  const query = searchParams.get('query');

  const politicianUrl = (
    searchExecuted ?
    `/politicians/${politician.slug}?search-generated=true&search-type=${searchType}&search-query=${query}` :
    `/politicians/${politician.slug}`
  );

  return (
    <Link
      css={styles.politicianCard(size)}
      to={politicianUrl}
    >
      <PoliticianImage
        politician={politician}
        history={history}
        size={size}
        isPoliticianCard={true}
      />
      <div css={styles.politicianName(size)}>
        {politician.name}
      </div>
      <div css={styles.politicianParty(size)}>
        {politician.political_party}
      </div>
      <div css={styles.politicianTitle(size)}>
        {politician.title}
      </div>
    </Link>
  );
};

PoliticianCard.propTypes = propTypes;
PoliticianCard.defaultProps = defaultProps;
export default PoliticianCard;
